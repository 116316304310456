.Navheader {

    background: linear-gradient(217deg, #ffff, rgba(255, 0, 0, 0) 70.71%),
        linear-gradient(107deg,
            #038e9f30, rgba(0, 255, 0, 0) 20.71%),
        linear-gradient(336deg,
            #ffff, rgba(0, 0, 255, 0) 70.71%);

}

.Content {
    position: absolute;
    left: 0;
    right: 0;
    background: linear-gradient(217deg, #ffff, rgba(255, 0, 0, 0) 70.71%),
        linear-gradient(137deg, #038e9f30, rgba(0, 255, 0, 0) 20.71%),
        linear-gradient(336deg, #ffff, rgba(0, 0, 255, 0) 70.71%),
        linear-gradient(to bottom left, #ffff, rgba(0, 0, 0, 0));

}

.PageContent {
    position: absolute;
    left: 0;
    right: 0;
    padding-top: 30px;
    background: linear-gradient(217deg, #ffff, rgba(255, 0, 0, 0) 70.71%),
        linear-gradient(137deg, #038e9f52, rgba(0, 255, 0, 0) 20.71%),
        linear-gradient(336deg, #ebe5e5, rgba(0, 0, 255, 0) 70.71%),
        linear-gradient(to bottom left, #ffff, rgba(0, 0, 0, 0));
}

.buttonGroup {
    display: flex;
    align-items: center;
    gap: 15px;
}

.StartButton {
    background-color: #0052AB;
    color: #ffff;
    padding: 10px 60px;
    border: none;
    border-radius: 25px;
    font-size: 20px;
    font-weight: 600;
}

.WatchButton {
    background-color: #038E9F;
    color: #ffff;
    padding: 10px 60px;
    border: none;
    border-radius: 25px;
    font-size: 17px;
    font-weight: 600;
    height: 55px;
}

.WatchButton{
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
}

a.carousel-control-prev {
    left: -100px;
}

a.carousel-control-next {
    right: -75px;
}

.line {
    border: 1px solid #0052AB;
    width: 15%;
    margin: 15px 0px;
}

.Line {
    border: 1px solid #DBDBDB;
}

.row {
    width: 100%;
}

.LoginInput {
    height: 85px;
    position: relative;
}

.LoginInput svg {
    position: absolute;
    bottom: 25px;
    right: 10px;

}
.OtpInput {
    height: 60px;
}

.ArrowIconClass {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0px 40px;
    margin-top: 25px;
    gap: 15px;
}

.BackIcon {
    background-color: white;
    padding: 10px;
    border-radius: 50px;
    color: black;
    margin-bottom: 8px;
}

.SearchInput {
    display: flex;
    position: relative;
    width: 40%;
}

.SearchInput .form-control {
    width: 100%;
    height: 50px;
    border: 6px;
    border: 1px solid #b8b8b8;
    z-index: 0;
}

.SearchInput .form-control::placeholder {
    font-family: Proxima Nova;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #828282;
}

.SearchInput .SearchIconBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 50px;
    position: absolute;
    right: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background: linear-gradient(89.98deg, #0052ab 0.01%, #038e9f 99.98%),
        linear-gradient(0deg, #027ca2, #027ca2);
}

.form-check-input:checked {
    background-color: #038E9F;
    border-color: #038E9F;
}

a:hover {
    color: #038E9F;
}

.ViewMoreButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.ViewMoreButton button {
    background: linear-gradient(45deg, #0052AB, #038E9F);
    border: none;
    border-radius: 3px;
    padding: 10px;
    font-size: 20px;
    font-weight: 700;
    color: #ffff;
}
input.form-control.is-invalid {
    border-color: transparent;
    background-image: none;
}

@media screen and (min-width: 991px) and (max-width: 1199px) {
    .StartButton {
        padding: 10px 55px;
        font-size: 15px;
    }

    .WatchButton {
        padding: 10px 55px;
        font-size: 15px;
    }
}

@media screen and (min-width: 770px) and (max-width: 990px) {
    .StartButton {
        padding: 10px 48px;
        font-size: 15px;
    }

    .WatchButton {
        padding: 10px 48px;
        font-size: 15px;
    }
}

@media screen and (max-width: 584px) {
    .StartButton {
        padding: 10px 48px;
        font-size: 15px;
    }

    .WatchButton {
        padding: 10px 48px;
        font-size: 15px;
    }

    .SearchInput {
        margin-top: 10px;
    }
}


@media (min-width: 320px) and (max-width: 771px) {
    .SearchInput {
        display: flex;
        position: relative;
        width: 100%;
    }

    .SearchInput .form-control {
        width: 100%;
        height: 45px;
        border: 6px;
        border: 1px solid #b8b8b8;
    }

    .SearchInput .SearchIconBox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 45px;
        position: absolute;
        right: 0;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        background: linear-gradient(89.98deg, #0052ab 0.01%, #038e9f 99.98%),
            linear-gradient(0deg, #027ca2, #027ca2);
    }
}