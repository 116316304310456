.SubscriptionPage {
    width: 100%;
    min-height: 100vh;
    background-image: url("../../Assests/Images/subscription.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 20px 0px;
}

.SubscriptionPage h2 {
    font-size: 35px;
    font-weight: 700;
    color: #ffff;
    margin: 20px 0px;
}

.SubscriptionPage p {
    font-size: 25px;
    font-weight: 400;
    color: #E1E1E1;
    margin-bottom: 40px;
}

.Column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.DashboardCard {
    width: 100%;
}

.DashboardCardInner {
    width: 296px;
    border-radius: 15px;
    height: 459px;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    background-color: #ffff;
    margin-bottom: 20px;
    padding: 20px 10px;
}

.DashboardCardInner span {
    color: #626262;
    font-size: 25px;
    font-weight: 600;
    margin: 20px 0px;
}

.DashboardCardInner h1 {
    font-family: Proxima Nova;
    font-size: 60px;
    font-weight: 600;
    line-height: 60px;
    

}

.DashboardCardInner ul li {
    font-family: Proxima Nova;
    font-size: 15px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    margin-bottom: 18px;
    color: #828282;
}

.Plan {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 296px;
    height: 100px;
    background: linear-gradient(45deg, #0052AB, #038E9F);
    border: none;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Plan h1 {
    font-size: 30px;
    font-weight: 600;
    line-height: 30px;
    color: #ffff;
}