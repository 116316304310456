.backgroundImage {
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
}

.textContent {
    position: absolute;
    top: 10%;
    width: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    padding-left: 20px;
    /* padding: 20px; */
}

.textContent h1 {
    font-size: 50px;
    font-weight: 800;
    color: #FFFFFF;
    line-height: inherit;
}


.textContent p {
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: inherit;
    font-family: "Proxima Nova";
}

.SearchAndCreate {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.SelectCategory {
    width: 180px;
    height: 50px;
    background: #ffffff;
    box-shadow: 0px 0px 15px 0px #0000000d;
    border: 1px solid #b8b8b8;
    border-radius: 6px;
    margin-left: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 12px;
    cursor: pointer;
}

.SelectCategory p {
    font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #828282;
    margin: 0;
}

.SelectCategory img {
    width: 18px;
    height: 9px;
}

.joinInfo {
    margin: 20px 0px;
    border: 1px solid #DEDEDE;
    padding: 20px;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
}

.classHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
}

.classHeading h1 {
    color: #038E9F;
    font-size: 30px;
    font-weight: 700;
}

.classHeading p {
    background-color: #fcc0a179;
    color: #FE8748;
    font-size: 15px;
    font-weight: 500;
    padding: 5px;
    border-radius: 2px;
    margin: 0;
}

.joinInfo h4 {
    font-size: 18px;
    font-weight: 600;
}

.classTimer {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
}

.joinInfo p {
    font-size: 20px;
    font-weight: 400;
}

.JoinNow {
    background: linear-gradient(45deg, #0052AB, #038E9F);
    border: none;
    border-radius: 3px;
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #ffff;
}

.Joined {
    background: #c5e1ff9c;
    border: none;
    border-radius: 3px;
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
    color:#0052AB;
}

@media only screen and (max-width: 767px) {
    .backgroundImage {
        height: 500px;
        object-fit: cover;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .textContent h1 {
        font-size: 31px;
        margin-bottom: 10px;
    }

    .textContent p {
        font-size: 18px;
    }

    .ContainerClass {
        max-width: unset !important;
        padding: 0 42px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .textContent h1 {
        font-size: 30px;
        margin: 5px 0px;

    }

    .textContent p {
        font-size: 18px;
        margin: 10px 0px;
    }

    .ContainerClass {
        max-width: unset !important;
        padding: 0px 30px;
    }

}

@media only screen and (max-width: 575px) {
    .textContent h1 {
        font-size: 32px;
        margin: 5px 0px;
    }

    .textContent p {
        font-size: 16px;
        margin: 10px 0px;
    }

    .ContainerClass {
        max-width: unset !important;
        padding: 0px 15px;
    }


    .SearchAndCreate {
        display: block;
        width: 100%;
    }

    .Create {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
    }

}

@media only screen and (max-width: 410px) {
    .textContent h1 {
        font-size: 28px;
        margin: 5px 0px;
    }

    .textContent p {
        font-size: 13px;
        margin: 10px 0px;
    }

    .ContainerClass {
        max-width: unset !important;
        padding: 0px 15px;
    }
}