.ModalHeading {
  font-family: Proxima Nova;
  font-size: 26px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  color: #000000;
}

.OptionArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.OptionArea p {
  margin: 0;
  font-family: Proxima Nova;
  font-size: 18px;
  font-weight: 600;
  line-height: 37px;
  letter-spacing: 0em;
  text-align: left;
  color: #383838;
}

.DoneButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DoneButton button {
  width: 47%;
  height: 55px;
  border-radius: 8px;
  background: #0052ab;
  border: 1px solid #0052ab;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: #ffffff;
  margin-bottom: -15px;
}

.Buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ---------answer submit css ------------- */
.Heading {
  background-color: #038E9F;
  color: white;
}

.AlertHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 22px;
  font-weight: 700;
}
.AlertBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AlertBody img {
  width: 100px;
  height: 100px;
  margin-top: 10px;
}

.AlertBody p {
  font-family: Proxima Nova;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  width: 83%;
  text-align: center;
  margin: 0;
  padding-top: 15px;
}
.AlertFooter {
  display: flex;
  justify-content: center;
}

.AlertYes {
  border-radius: 28px;
  background: #038E9F;
  color: #ffffff;
  border: #038E9F;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  padding: 10px 100px;
}
