.MainPage {
  width: 100%;
  height: 100vh;
  background-image: url("../Assests/Images/loginBackground.png");
  background-size: cover;
  overflow-y: auto;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.loginContent {
  width: 100%;
  height: 100vh;
  background-image: url("../Assests/Images/loginPage.png");
  background-size: cover;
  overflow-y: auto;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  scrollbar-width: none;
}

.FieldSection {
  padding: 20px 40px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Heading {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.Heading h1 {
  font-family: Proxima Nova;
  font-size: 40px;
  font-weight: 700;
  margin: 0;
  color: #ffff;
  margin: 10px 0px 5px 0px;
}

.Heading p {
  font-family: Proxima Nova;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #ffff;
  margin-bottom: 10px;
}

.LoginInputSection {
  width: 100%;
  margin: 10px 0px 15px 0px;
}

.ForgetPassword {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
}

.ForgetPassword p {
  font-family: Proxima Nova;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffff;
  cursor: pointer;
}

.LoginButton {
  width: 100%;
  margin-top: 30px;
  border-radius: 28px;
  height: 47px;
  background: #ffff;
  border: 1px solid #0052ab;
  font-family: Proxima Nova;
  font-size: 22px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  color: #0052AB;
}

.LoginImage {
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

}

.Label {
  width: 100%;
  text-align: left;
  color: white;
}

.NoAccount {
  color: #ffff;
  width: 100%;
  height: 10px;
}

.NoAccount p {
  color:
    #87E7F3;
}

.NoAccount span {
  color: #ffff
}

.SubmitButton {
  width: 100%;
  margin-top: 20px;
  border-radius: 28px;
  height: 47px;
  background: #038E9F;
  border: 1px solid #038E9F;
  font-family: Proxima Nova;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  color: #ffff;
}

.CancelButton {
  width: 100%;
  margin-top: 20px;
  border-radius: 28px;
  height: 47px;
  background: transparent;
  border: 1px solid #ffff;
  font-family: Proxima Nova;
  font-size: 22px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  color: #ffff;
}

.OTPInput {
  width: 60px;
  height: 35px;
  gap: 20px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}