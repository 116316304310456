.HomeContent {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    /* background: linear-gradient(to bottom right, #ffff 80%, #aac9eb 10.80%); */
    
}

.ClassInfo h1 {
    font-size: 82px;
    font-weight: 800;
    text-align: left;
}

.ClassInfo span {
    color: #038E9F;
}

.ClassInfo p {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #555555;
    line-height: 30px;
}

.SubjectDescription p {
    font-size: 20px;
    font-weight: 600;
    color: #038E9F;
    margin-top: 20px;
    text-align: center;
}

.CatogoryPage {
    background-color: #0052AB;
    padding: 30px;
}

.Heading {
    margin-bottom: 30px;
    text-align: center;
}

.Heading h1 {
    color: #ffff;
    font-size: 40px;
    font-weight: 600;
}

.Heading p {
    color: #ffff;
    font-size: 20px;
    font-weight: 400;
    margin: 0px 50px;
}

.DashboardCardInner {
    width: 90%;
    border-radius: 6px;
    height: 350px;
    background-color: #ffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    padding: 10px;
}

.DashboardCardInner img {
    width: 110px;
    height: 110px;
    margin-bottom: 10px;

}

.DashboardCardInner h1 {
    font-size: 20px;
    font-weight: 700;
}

.DashboardCardInner p {
    font-size: 15px;
    font-weight: 400;
    color: #333333;
}

.ImageSection{
    display: flex;
    justify-content: center;
    align-items: center;
}
.ImageSection img {
    width: 600px;
    height: 500px;
}




/* ----------verbal css ------------- */
.Devices{
    display: flex;
    justify-content: center;
    align-items: center;
}
.Devices img {
    width: 480px;
    height: 330px;
}

.TopicHeading h1 {
    color: #202020;
    font-size: 50px;
    font-weight: 800;
}

.TopicHeading p {
    color: #333333;
    font-size: 20px;
    font-weight: 400;
}

.TopicHeading ul li {
    color: #333333;
    font-size: 20px;
    font-weight: 400;
    padding: 10px 0px;
}

/* --------footer css---- */
.FooterBody {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Contact h6 {
    font-size: 15px;
    font-weight: 400;
    color: #202020;
    margin-bottom: 15px;
    line-height: 22px;
}

.Contact p {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
}

.Navigation h6 {
    font-size: 20px;
    font-weight: 600;
    color: #202020;
    margin-bottom: 15px;
}

.Navigation p {
    font-size: 15px;
    font-weight: 400;
    color: #202020;
    text-decoration: none;
}

.formInput {
    height: 30px;
    margin: 20px 0px;
}

.Navigation img {
    width: 80px;
    height: 80px;
}

/* --------popular course---- */
.CoursePage {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0px;
}

.CourseHeading {
    width: 30%;
}

.CardGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 75%;
}

.CardData1 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #0052AB;
    color: #ffff;
    border-radius: 20px;
    padding: 10px;
    width: 500px;
}

.CardData1 h1 {
    font-size: 20px;
    line-height: 25px;
}

.CardData1 p {
    font-size: 15px;

}

.CardData1 button {
    border: none;
    border-radius: 20px;
    padding: 5px 15px;
    color: #0052AB;
}

.CardData1 img {
    width: 230px;
    height: 210px;
    object-fit: cover;
}

.ContainerClass img {
    margin: 20px 0px;
}

@media screen and (min-width: 991px) and (max-width: 1199px) {


    .ImageSection img {
        width: 450px;
        height: 400px;
    }

    .ClassInfo h1 {
        font-size: 62px;
    }
    .ClassInfo p {
        font-size: 17px;
    }
    .Devices img {
        width: 440px;
        height: 330px;
    }
    
    .TopicHeading h1 {
        font-size: 50px;
    }
    
    .TopicHeading p {
        font-size: 20px;
    }
}

@media screen and (min-width: 776px) and (max-width: 991px) {
    .ContainerClass {
        max-width: unset !important;
        padding: 0 42px;
    }
    .ImageSection img {
        width: 370px;
        height: 340px;
    }

    .ClassInfo h1 {
        font-size: 55px;
    }
    .ClassInfo p {
        font-size: 14px;
    }
    .Devices img {
        width: 350px;
        height: 300px;
    }
    
    .TopicHeading h1 {
        font-size: 50px;
    }
    
    .TopicHeading p {
        font-size: 15px;
    }

}

@media screen and (min-width: 585px) and (max-width: 775px) {
    .ContainerClass {
        max-width: unset !important;
        padding: 10px 30px;
    }
    .ImageSection img {
        width: 350px;
        height: 300px;
    }

    .ClassInfo h1 {
        font-size: 56px;
    }
    .ClassInfo p {
        font-size: 14px;
    }
    .Devices img {
        width: 320px;
        height: 280px;
    }
    
    .TopicHeading h1 {
        font-size: 50px;
    }
    
    .TopicHeading p {
        font-size: 15px;
    }
}

@media screen and (max-width: 584px) {
    .ContainerClass {
        max-width: unset !important;
        padding: 10px 15px;
    }
    .ImageSection img {
        width: 330px;
        height: 300px;
    }

    .ClassInfo h1 {
        font-size: 56px;
    }
    .ClassInfo p {
        font-size: 14px;
    }
    .Devices img {
        width: 300px;
        height: 280px;
    }
    
    .TopicHeading h1 {
        font-size: 50px;
    }
    
    .TopicHeading p {
        font-size: 15px;
    }
}