.DataBaseHeading h1 {
    font-size: 36px;
    font-weight: 700;
    color: #0052AB;
    margin: 15px 0px;
}

.Topics {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #D1D1D1;
    border-radius: 8px;
    padding: 20px;
    background-color: white;
    gap: 100px;
}

.DataBaseHeading p {

    font-size: 25px;
    font-weight: 400;
    margin: 0;
}

.DataBaseHeading span {
    color: #828282;
}

.DataBaseHeading h3 {
    font-size: 30px;
    font-weight: 600;
    margin-top: 15px;
}

.DataBaseHeading p {
    font-size: 20px;
    font-weight: 400;
    color: #504F4F;
}

.QuesAns {
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    padding: 20px;
    background-color: white;
    margin-bottom: 20px;
}

.Question {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Question h1 {
    font-size: 22px;
    font-weight: 600;
    color:
        #575757;
}

.Options {
    display: flex;
    flex-direction: column;
    gap: 20px;

}
.Question img {
    margin-left: 10px;
}

.label {
    display: flex;
    gap: 10px;
}

.SubmitButton {
    display: flex;
    justify-content: end;
    align-items: center;
}

.SubmitButton button {
    background-color: #00B000;
    color: #ffff;
    border: none;
    padding: 7px 20px;
    border-radius: 4px;
}

@media only screen and (min-width: 550px) and (max-width: 700px) {
    .Topics {
        gap: 30px;
    }

    .DataBaseHeading p {
        font-size: 18px;
    }
}

@media only screen and (max-width: 550px) {
    .Topics {
        flex-direction: column;
        gap: 10px;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .DataBaseHeading p {
        font-size: 18px;
    }

    .Question h1 {
        font-size: 18px;
    }
}