.Heading {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
}

.Heading .modal-header .btn-close {
    height: 37px;
    margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
    color: #626262;
}

.AlertHeading {
    font-family: Proxima Nova;
    font-size: 26px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
}

.AlertBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.AlertBody img {
    width: 100px;
    height: 100px;
    margin-top: 10px;
}

.AlertBody p {
    font-family: Proxima Nova;
    font-size: 22px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0em;
    color: #000000;
    width: 63%;
    text-align: center;
    margin: 0;
    padding-top: 15px;
}

.AlertFooter {
    display: flex;
    justify-content: center;
}

.AlertYes {
    border-radius: 28px;
    background: #038E9F;
    color: #ffffff;
    border: #038E9F;
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    padding: 10px 30px;
}

@media (min-width: 320px) and (max-width: 505px) {
    .AlertFooter {
        margin: 0px 40px;
        display: flex;
        justify-content: center;
    }

    .AlertYes {
        width: 160px;
        height: 45px;
        border-radius: 8px;
        background: #0052ab;
        color: #ffffff;
        border: #0052ab;
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
    }

    .AlertCancel {
        width: 160px;
        height: 45px;
        border-radius: 8px;
        background: #e74f5b;
        color: #ffffff;
        border: #e74f5b;
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
    }
}