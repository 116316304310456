.backgroundImage {
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    border: 1px solid #C7C7C7;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
    .backgroundImage {
        height: 500px;
        object-fit: cover;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.DataBaseHeading h6{
    color: #038E9F;
    font-size: 20px;
    font-weight: 600;
}
.DataBaseHeading h1 {
    font-size: 30px;
    font-weight: 700;
    margin: 15px 0px;
}

.Topics {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #D1D1D1;
    border-radius: 8px;
    padding: 20px;
    background-color: white;
    gap: 100px;
}

.DataBaseHeading p {

    font-size: 25px;
    font-weight: 400;
    margin: 0;
}

.DataBaseHeading span {
    color: #828282;
}

.DataBaseHeading h3 {
    font-size: 25px;
    font-weight: 600;
}

.DataBaseHeading p {
    font-size: 20px;
    font-weight: 400;
    color: #504F4F;
}

.QuesAns {
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    padding: 20px;
    background-color: white;
    margin-bottom: 20px;
}

.Question {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Question h1 {
    font-size: 22px;
    font-weight: 600;
    color:
        #575757;
}

.Options {
    display: flex;
    flex-direction: column;
    gap: 20px;

}
.Question img {
    margin-left: 10px;
}

.label {
    display: flex;
    gap: 10px;
}

.SubmitButton {
    display: flex;
    justify-content: end;
    align-items: center;
}

.SubmitButton button {
    background-color: #00B000;
    color: #ffff;
    border: none;
    padding: 7px 20px;
    border-radius: 4px;
}

.StudyNotesHeading {
    background-color: #0052AB;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 10px;
    padding: 10px;
    margin: 20px 0px;
}

.StudyNotesHeading h1 {
    font-size: 40px;
    font-weight: 700;
    /* border-bottom: 2px solid #06AFC3; */
    padding: 10px;

}

@media only screen and (min-width: 550px) and (max-width: 700px) {
    .Topics {
        gap: 30px;
    }

    .DataBaseHeading p {
        font-size: 18px;
    }
    .StudyNotesHeading h1 {
        font-size: 26px;

    }
}

@media only screen and (max-width: 550px) {
    .Topics {
        flex-direction: column;
        gap: 10px;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .DataBaseHeading p {
        font-size: 18px;
    }

    .Question h1 {
        font-size: 18px;
    }
    .StudyNotesHeading h1 {
        font-size: 20px;

    }
}