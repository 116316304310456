.textContent {
    text-align: center;
    margin: 20px 0px;
}

.textContent h1 {
    font-size: 35px;
    font-weight: 700;
    color: #333333;
    line-height: 35px;
    margin: 20px 0px;
}

.textContent p {
    font-size: 25px;
    font-weight: 400;
    color: #626262;
    line-height: 45px
}

.joinInfo {
    margin: 20px 0px;
    border: 1px solid #DEDEDE;
    padding: 20px;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
    height: 373px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}
.BackIcon {
    background-color:#0052AB;
    padding: 10px;
    border-radius: 50px;
    color: #ffff;
    margin-bottom: 8px;
}
.FolderGroup{
    height: 373px;
    margin: 20px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}
.FolderGroup img{
    border: 3px solid #D4D4D4;
    padding: 20px;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
}