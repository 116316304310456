.hideContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #DDDDDD;
  border-radius: 50px;
  padding: 0px 20px;
  margin-top: 30px;
  background-color: #ffff;
}

@media screen and (min-width: 990px) and (max-width: 1199px) {
  .hideContainer {
    margin-top: 30px;
    padding: 0px 20px;
  }
}

@media screen and (min-width: 776px) and (max-width: 991px) {
  .hideContainer {
    max-width: unset !important;
    border: none;
    margin: 0 42px;
    border: none;
    background-color: transparent;
  }

}

@media screen and (min-width: 585px) and (max-width: 775px) {
  .hideContainer {
    max-width: unset !important;
    margin: 0px 30px;
    border: none;
    border: none;
    background-color: transparent;
  }
}
@media screen and (max-width: 584px) {
  .hideContainer {
    max-width: unset !important;
    margin: 0px 15px;
    border: none;
    border: none;
    background-color: transparent;
  }
}

.topNavLogo {
  height: 72px;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
}


.test {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbarLogoImg {
  height: 52px;
  width: 107px;
}

.navItem {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.navItems {
  text-decoration: none;
  color: #333333;
  padding: 10px;
  margin-right: 5px;
  font-size: 18px;
  font-weight: 600;
}

.signupButton {
  text-decoration: none;
  color: #038E9F;
  padding: 10px;
  margin-right: 5px;
  font-size: 18px;
  font-weight: 600;
}


.signinButton {
  text-decoration: none;
  background-color: #0052AB;
  color: #ffff;
  padding: 10px;
  margin-right: 5px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 30px;
  padding: 10px 25px;
}

.bellIcon{
  margin: 0px 20px;
}


@media (max-width: 970px) {

  .showLogo {
    display: block;
  }

  .navItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    left: 0;
    top: 105px;
    background-color: white;
  }

  .navItem {
    padding: 8px;
    align-items: start;
  }

  .navbar-collapse.show+.navItem {
    display: flex;
  }
}

@media screen and (min-width: 990px) and (max-width: 1200px) {
  .navItems {

    font-size: 14px;
  }
}