.backgroundImage {
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .backgroundImage {
        height: 500px;
        object-fit: cover;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.textContent {
    position: absolute;
    top: 5%;
    width: 60%;
    text-align: left;
    color: white;
    padding-left: 20px;
    /* padding: 20px; */
}

.textContent h1 {
    font-size: 36px;
    font-weight: 700;
    color: #FFFFFF;
    line-height: inherit;
    margin-bottom: 30px;
}

.textContent span {
    color: #06AFC3
}

.textContent p {
    font-size: 25px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: inherit;
}

.textContent button {
    background: linear-gradient(45deg, #0052AB, #038E9F);
    border: none;
    border-radius: 3px;
    padding: 10px;
    font-size: 20px;
    font-weight: 700;
    color: #ffff;
}

.StudyNotesHeading {
    background-color: #0052AB;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 10px;
    padding: 10px;
    margin: 20px 0px;
}

.StudyNotesHeading h1 {
    font-size: 40px;
    font-weight: 700;
    /* border-bottom: 2px solid #06AFC3; */
    padding: 10px;

}

.SearchAndCreate {
    display: flex;
    justify-content: end;
    align-items: end;
    width: 100%;
    margin-top: 20px;
    gap: 20px;
}

.Create {
    /* width: 50%; */
    display: flex;
    justify-content: space-between;
}

.SelectCategory {
    width: 180px;
    height: 50px;
    background: #ffffff;
    box-shadow: 0px 0px 15px 0px #0000000d;
    border: 1px solid #b8b8b8;
    border-radius: 6px;
    margin-left: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 12px;
    cursor: pointer;
}

.SelectCategory p {
    font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #828282;
    margin: 0;
}

.SelectCategory img {
    width: 18px;
    height: 9px;
}

.NotesAndClassSection {
    width: 100%;
    margin: 20px 0px;
}

.VideoContent {
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
}

.VideoContent img {
    width: 318px;
    height: 200px;
    border-radius: 6px;
    margin-right: 20px;
}

.VideoHeading p {
    font-family: Proxima Nova;
    font-size: 30px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    color: #202020;
    margin-top: 0px;
}

.VideoDescription {
    font-size: 20px;
    font-weight: 400;
    color: #828282;
    margin-bottom: 30px;
}

.Category {
    display: flex;
    margin-top: -5px;
}

.Category h1 {
    font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 600;
    line-height: 10px;
    letter-spacing: 0em;
    color: #000000;
}

.Category p {
    font-family: Proxima Nova;
    font-size: 16px;
    font-weight: 600;
    line-height: 10px;
    letter-spacing: 0em;
    color: #868686;
    margin-left: 5px;
}
.heading{
    font-size: 30px;
    font-weight: 700;
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .textContent h1 {
        font-size: 30px;
    }
}


@media only screen and (min-width: 768px) and (max-width: 991px) {
    .textContent h1 {
        font-size: 31px;
        margin-bottom: 10px;
    }

    .textContent p {
        font-size: 18px;
    }

    .textContent button {
        font-size: 18px;
    }

    .ContainerClass {
        max-width: unset !important;
        padding: 0 42px;
    }

    .VideoContent img {
        width: 300px;
        height: 200px;
    }

    .VideoHeading p {
        font-size: 27px;
    }

    .VideoDescription {
        font-size: 18px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .textContent h1 {
        font-size: 30px;
        margin: 5px 0px;

    }

    .textContent p {
        font-size: 18px;
        margin: 10px 0px;
    }

    .textContent button {
        font-size: 16px;
    }

    .ContainerClass {
        max-width: unset !important;
        padding: 0px 30px;
    }

    .StudyNotesHeading h1 {
        font-size: 30px;

    }

    .VideoContent img {
        width: 270px;
        height: 180px;
    }

    .VideoHeading p {
        font-size: 24px;
    }

    .VideoDescription {
        font-size: 14px;
    }

}

@media only screen and (max-width: 575px) {
    .textContent h1 {
        font-size: 32px;
        margin: 5px 0px;
    }

    .textContent p {
        font-size: 16px;
        margin: 10px 0px;
    }

    .textContent button {
        font-size: 14px;
    }

    .ContainerClass {
        max-width: unset !important;
        padding: 0px 15px;
    }

    .StudyNotesHeading h1 {
        font-size: 20px;

    }

    .SearchAndCreate {
        display: block;
        width: 100%;
    }

    .Create {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
    }

    .VideoContent {
        display: block;
    }

    .VideoContent img {
        width: 240px;
        height: 200px;
    }

    .VideoHeading p {
        font-size: 20px;
        margin-top: 10px;
    }

    .VideoDescription {
        font-size: 15px;
    }

}

@media only screen and (max-width: 410px) {
    .textContent h1 {
        font-size: 28px;
        margin: 5px 0px;
    }

    .textContent p {
        font-size: 13px;
        margin: 10px 0px;
    }

    .textContent button {
        font-size: 12px;
    }

    .ContainerClass {
        max-width: unset !important;
        padding: 0px 15px;
    }

    .VideoContent {
        display: block;
    }
}

